<template>
  <div>
    <Bread BreadTitle="南山律"></Bread>
    <div class="row">
      <div class="col-md-4 col-8 mb-1">
        <div class="input-group">
          <span class="input-group-text">備覽頁數</span>
          <input
            type="text"
            id="tbxPageNum"
            class="form-control"
            v-model="basedata.NanPageNum"
          />
        </div>
      </div>
      <div class="col-md-2 col-4">
        <div class="input-group">
          <button class="btn btn-primary btn-sm multi-btn" @click="DoNanRead">
            閱讀
          </button>
          <button
            class="btn btn-success btn-sm multi-btn multi-btn"
            @click="BackupRecord"
            v-if="basedata.vTargetURL != ''"
          >
            複製紀錄
          </button>
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-6 mb-1">
        <div class="input-group">
          <input
            type="text"
            v-model="basedata.jsonstrload"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6 mb-1">
        <div class="input-group">
          <button class="btn btn-success btn-sm" @click="LoadRecord">
            匯入紀錄
          </button>
        </div>
      </div>
      <div class="col-md-4 col-12 mb-1">
        <div class="input-group">
          <span class="input-group-text">【備覽】冊</span>
          <input
            type="text"
            id="tbxBook"
            class="form-control"
            v-model="basedata.vBook"
          />
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-12">
        <div class="input-group">
          <span class="input-group-text">AB</span>
          <div class="input-group-append form-control">
            <label
              ><input
                type="radio"
                name="optradio"
                value="A"
                v-model="basedata.vAB"
              />A</label
            >
            <label
              ><input
                type="radio"
                name="optradio"
                value="B"
                v-model="basedata.vAB"
              />B</label
            >
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-12">
        <div class="input-group">
          <input
            type="button"
            value="閱讀"
            id="btnRead"
            class="btn btn-primary multi-btn btn-sm"
            @click="DoNSLRead"
          />
          <input
            type="button"
            value="播放"
            id="btnLamAdd"
            class="btn btn-primary multi-btn btn-sm"
            @click="DoNSLAdd"
          />
        </div>
      </div>

      <div class="col-md-12 col-sm-12 col-sm-12">
        <AudiPlay :PlayURL="basedata.vTargetURL" ref="compAPlay" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch } from "vue";
import AudiPlay from "../components/AudiPlay.vue";
import Bread from "../components/Bread.vue";
import myBackup from "@/composables/Record/Backup";
import myLoad from "@/composables/Record/Load";
export default {
  name: "Larm",
  components: {
    AudiPlay,
    Bread,
  },
  setup() {
    const divRef = ref(AudiPlay);
    const basedata = reactive({
      list: [],
      arrayRecordTime: new Array(),
      vBook: "",
      vAB: "A",
      vTargetURL: "",
      NanPageNum: "",
      jsonstrload: "",
    });

    function DoNSLAdd() {
      //將 arrayRecordTime 陣列清空
      basedata.arrayRecordTime = [];

      if (basedata.vBook == "") {
        alert("請先輸入第幾冊!");
        return false;
      }

      let vOriBook = "0" + basedata.vBook;
      let vBook = vOriBook.substr(vOriBook.length - 2, 2);

      basedata.vTargetURL =
        "https://www.theqi.com/buddhism/NSL/NSL_" +
        vBook +
        basedata.vAB.toUpperCase() +
        ".mp3";

      divRef.value.PlayURL = basedata.vTargetURL;
      divRef.value.methods.DoPlay(basedata.vTargetURL);
    }

    function DoNSLRead() {
      if (basedata.vBook == "") {
        alert("請先輸入第幾冊!");
        return false;
      }

      let vOriBook = "0" + basedata.vBook;
      let vBook = vOriBook.substr(vOriBook.length - 2, 2);

      var vLink =
        "https://www.theqi.com/buddhism/NSL/data/p" +
        vBook +
        basedata.vAB.toLowerCase() +
        ".html";
      window.open(vLink, "_blank");
    }

    function DoNanRead() {
      var vUserInput = basedata.NanPageNum;
      if (isNaN(vUserInput)) {
        alert("請輸入正確的頁數");
        return;
      }

      var vPage = "nanbook.html?num=" + vUserInput;

      window.open(vPage, "_blank");
    }

    function BackupRecord() {
      //Call Composition API :Record/Backup.js
      myBackup(basedata);
    }

    function LoadRecord() {
      //Call Composition API :Record/Load.js
      myLoad(basedata);
    }

    onMounted(() => {
      divRef.value.PlayURL = "";

      var vRecordLocalStoragenanBook = localStorage.getItem("nanBook");
      if (vRecordLocalStoragenanBook == null) vRecordLocalStoragenanBook = "";
      basedata.vBook = vRecordLocalStoragenanBook;

      var vRecordLocalStoragenanBookAB = localStorage.getItem("nanBookAB");
      if (vRecordLocalStoragenanBookAB == null)
        vRecordLocalStoragenanBookAB = "A";
      basedata.vAB = vRecordLocalStoragenanBookAB;

      var vRecordLocalStoragenanPageNum = localStorage.getItem("nanPageNum");
      if (vRecordLocalStoragenanPageNum == null)
        vRecordLocalStoragenanPageNum = "";
      basedata.NanPageNum = vRecordLocalStoragenanPageNum;
    });

    watch(
      () => basedata.vBook,
      (vNew, vOld) => {
        console.log("basedata.vBook-vNew", vNew);
        console.log("basedata.vBook-vOld", vOld);

        //存 local storage
        localStorage.setItem("nanBook", vNew);
      }
    );

    watch(
      () => basedata.vAB,
      (vNew, vOld) => {
        console.log("basedata.vAB-vNew", vNew);
        console.log("basedata.vAB-vOld", vOld);

        //存 local storage
        localStorage.setItem("nanBookAB", vNew);
      }
    );

    watch(
      () => basedata.NanPageNum,
      (vNew, vOld) => {
        console.log("basedata.NanPageNum-vNew", vNew);
        console.log("basedata.NanPageNum-vOld", vOld);

        //存 local storage
        localStorage.setItem("nanPageNum", vNew);
      }
    );

    return {
      DoNSLAdd,
      DoNSLRead,
      basedata,
      divRef,
      DoNanRead,
      BackupRecord,
      LoadRecord,
    };
  },
  methods: {
    SetUrl() {
      return this.basedata.vTargetURL;
    },
  },
};
</script>

<style>
.form-control label {
  padding-right: 20px;
}

.multi-btn {
  margin: 0.5em 0.5em;
}
</style>